
import { Box, CircularProgress, IconButton, TextField, Typography } from '@mui/material';
import { Translate as TranslateIcon, Done as DoneIcon, Cancel as CancelIcon } from '@mui/icons-material';

import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { translateKey } from '../../apis/projects';
import { updateTranslation } from '../../apis/translations';

interface TranslationProps {
    id: string;
    keyId: string;
    source_language: string;
    target_language: string;
    source_text: string;
    target_text: string;
    defaultLanguage: string;
    refreshOnTranslation: Function;
}

//key={translationIndex}
export const Translation: React.FC<TranslationProps> = (translation: TranslationProps) => {
    var isDefault = translation.source_language === translation.target_language;
    const { projectId } = useParams();
    const [processing, setProcessing] = useState<boolean>(false)
    const [sourceText, setSourceText] = useState<string>(translation.source_text);
    const [targetText, setTargetText] = useState<string>(translation.target_text);
    const [targetLanguage, setTargetLanguage] = useState<string>(translation.target_language)
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [previousSource, setPreviousSource ] = useState<string>(translation.target_text)

    useEffect(() => {
        setTargetLanguage(translation.defaultLanguage);
    }, [translation.source_language]);

    const updateSource = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (previousSource !== targetText ) {
            setIsEditing(true);
        }
        if (isDefault) {
            setSourceText(event.target.value);
        }
        setTargetText(event.target.value);
    }

    const translate = (event: any) => {
        console.log('Translating:', sourceText);
        if (!projectId) return;
        setProcessing(true);
        translateKey(projectId, translation.keyId).then(
            (res:any) => {
                console.log('Translation:', translation);
                setTargetText(res.data.target_text);
                setTargetLanguage(res.data.target_language);
                translation.refreshOnTranslation(translation.keyId);
            }
        ).catch((err:any) => {
            console.error('Error translating:', err);
        })
        .finally(() => {
            setProcessing(false);
        })
    }

    const validate = (event: any) => {
        console.log('Validating:', sourceText);
        setPreviousSource(sourceText);
        setIsEditing(false);

        if (!projectId) return;
        updateTranslation(
            projectId,
            translation.keyId,
            translation.id,
            {
                "source_text": sourceText,
                "target_text": targetText,
                "source_language": translation.source_language,
                "target_language": translation.target_language,
                "status": isDefault ? "approved" : "forced"
            }
        ).then(
            (res:any) => {
                console.log('Translation updated:', res);
            }
        ).catch((err:any) => {
            console.error('Error updating translation:', err);
        });
    }

    const cancel = (event: any) => {
        console.log('Validating:', sourceText);
        setSourceText(previousSource);
        setIsEditing(false);
    }

    return (
        <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center", width:"100%" }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', verticalAlign:"center", ml: 2, mt: "15px", mb: 1, width:"100%" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', verticalAlign:"center", justifyContent:"space-between", my:"auto", mr: 2}}>
                    <Typography component="div" sx={{ fontSize: "20px", fontStyle:"italic", color:"#858585", mx: "auto"}}>
                        {`${getUnicodeFlagIcon(translation.target_language.split('-')[1])} ${isDefault ? "*" : ""}`} 
                        
                    </Typography>
                    <Typography component="div" sx={{ fontSize: "10px", fontStyle:"italic", color:"#858585", mx: "auto"}}>
                        {translation.target_language.split('-')[0]}
                    </Typography>
                </Box>
                <TextField 
                    value={isDefault ? sourceText : targetText}
                    label={isDefault ? "Primary sentence" : "Translation"}
                    onChange={updateSource}
                    sx={{ width: "90%", ml: `${isDefault ? "0px" : "14px"}`}} 
                />
                {isDefault && !isEditing &&
                    <IconButton onClick={translate} sx={{ }}>
                        { !processing && <TranslateIcon />}
                        { processing && <CircularProgress />}
                    </IconButton>
                }
                {isEditing &&
                    <>
                        <IconButton onClick={validate} sx={{ }}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton onClick={cancel} sx={{ }}>
                            <CancelIcon />
                        </IconButton>
                    </>
                }
            </Box>
            {isDefault &&
                <Typography component="div" sx={{ fontSize: "10px", fontStyle:"italic", color:"#858585", my:"auto", ml: 2}}>
                    Mandatory, * indicates default language.
                </Typography>
            }
            {!isDefault &&
                <Typography component="div" sx={{ fontSize: "10px", fontStyle:"italic", color:"#858585", my:"auto", ml: 2}}>
                    {translation.source_text ?  translation.source_text : "No source text yet"}
                </Typography>
            }
        </Box>
    );
}