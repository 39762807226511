import { createTheme, PaletteColor } from "@mui/material";


export const { palette } = createTheme();

declare module "@mui/material/styles" {
    interface Palette {
        thirdary: PaletteColor;
    }
    interface PaletteOptions {
        thirdary: PaletteColor;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsColorOverrides {
        thirdary: true;
    }
}

// 👇 Custom theme to extend the base theme
export const customTheme = createTheme({
    palette: {
        primary: {
            main: '#274C77',
            light: '#386090',
            dark: '#1C3A5A',
            contrastText: '#fff', // Text color that contrasts with the primary color
        },
        secondary: {
            main: '#4C74A6',
            light: '#abc9f7',
            dark: '#335A8F',
            contrastText: '#fff', // Text color that contrasts with the secondary color
        },
        background: {
            default: '#386090',
        },
        thirdary: palette.augmentColor({
            color: {
                main: '#ffd88f',
                light: '#FFF3D8',
                dark: '#a98748',
                contrastText: '#000',
            }
        }),

    }
});