import React from "react";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const Logo = () => {
    return (
        <Box sx={{ margin: "auto" }}>
            <Link to="/" style={{ display: "flex" }}>
                <Box component="img" src="/assets/images/secondbrain.svg" alt="logo" sx={{ margin: "auto", width: "60px" }} />
            </Link>
        </Box>
    );
};

export default Logo;