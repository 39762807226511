import React, { useEffect, useState } from "react";
import {
	List,
	ListItem,
	ListItemText,
	TextField,
	IconButton,
	Box,
	Button,
	Paper,
	Typography,
	Divider,
} from "@mui/material";
import { TopBarHeader } from "../../components/header";
import { useParams } from "react-router-dom";
import {
	createKey,
	getKey,
	getProject,
	listKeys,
	searchKeys,
} from "../../apis/projects";
import { searchTranslation } from "../../apis/translations";
import SearchIcon from "@mui/icons-material/Search";
import { Key } from "../../components/key";

interface KeyType {
	id: string;
	key: string;
	context: string;
	translations: Translation[];
}

interface Translation {
	id: string;
	key: string;
	source_language: string;
	target_language: string;
	source_text: string;
	target_text: string;
}

const SearchBar = (props: { setSearchQuery: Function; label: string }) => (
	<form>
		<TextField
			id="search-bar"
			className="text"
			onInput={(e: any) => {
				props.setSearchQuery(e.target.value);
			}}
			InputProps={{
				startAdornment: (
					<SearchIcon
						sx={{
							mr: 1,
						}}
					/>
				),
			}}
			label={props.label}
			variant="outlined"
			placeholder="Search..."
			size="small"
		/>
	</form>
);

const KeysList: React.FC = () => {
	const { projectId } = useParams();
	const [keys, setKeys] = useState<KeyType[]>([]);
	const [project, setProject] = useState<any>([]);
	const [nextCursor, setNextCursor] = useState<string>("");
	const [previousCursor, setPreviousCursor] = useState<string>("");
	const [keysNumber, setKeysNumber] = useState<number>(0);

	const [searchQuery, setSearchQuery] = useState<string>("");
	const [searchTranslationQuery, setSearchTranslationQuery] =
		useState<string>("");
	const [prevSearchQuery, setPrevSearchQuery] = useState<string>("");
	const [prevSearchTranslationQuery, setPrevSearchTranslationQuery] =
		useState<string>("");

	useEffect(() => {
		refresh();
		if (!projectId) return;
		getProject(projectId)
			.then((res: any) => {
				setProject(res.data);
			})
			.catch((err: any) => {
				console.error("Error fetching project:", err);
			});
	}, []);

	const refresh = () => {
		if (!projectId) return;
		listKeys(projectId, "", true)
			.then((res: any) => {
				setKeys(res.data.values);
				setPreviousCursor(res.data.prev);
				setNextCursor(res.data.next);
				setKeysNumber(res.data.$count);
			})
			.catch((err: any) => {
				console.error("Error fetching keys:", err);
			});
	};

	const getNextPage = () => {
		if (!projectId) return;
		if (searchQuery) {
			searchKeys(projectId, `name==${searchQuery}`, nextCursor)
				.then((res: any) => {
					setKeys(res.data.values);
					setPreviousCursor(res.data.prev);
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
			return;
		} else if (searchTranslationQuery) {
			searchTranslation(
				projectId,
				`name==${searchTranslationQuery}`,
				previousCursor
			)
				.then((res: any) => {
					setKeys(res.data.values);
					setPreviousCursor(res.data.prev);
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
			return;
		} else {
			listKeys(projectId, nextCursor, false)
				.then((res: any) => {
					setKeys(res.data.values);
					if (res.data.prev) {
						setPreviousCursor(res.data.prev);
					}
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
		}
	};

	const getPreviousPage = () => {
		if (!projectId) return;
		if (searchQuery) {
			searchKeys(projectId, `name==${searchQuery}`, previousCursor)
				.then((res: any) => {
					setKeys(res.data.values);
					setPreviousCursor(res.data.prev);
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
			return;
		} else if (searchTranslationQuery) {
			searchTranslation(
				projectId,
				`name==${searchTranslationQuery}`,
				previousCursor
			)
				.then((res: any) => {
					setKeys(res.data.values);
					setPreviousCursor(res.data.prev);
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
			return;
		} else {
			listKeys(projectId, previousCursor, false)
				.then((res: any) => {
					setKeys(res.data.values);
					setPreviousCursor(res.data.prev);
					setNextCursor(res.data.next);
				})
				.catch((err: any) => {
					console.error("Error fetching keys:", err);
				});
		}
	};

	useEffect(() => {
		if (!projectId) return;
		if (searchQuery === prevSearchQuery) return;
		setPrevSearchQuery(searchQuery);
		searchKeys(projectId, `name==${searchQuery}`)
			.then((res: any) => {
				setKeys(res.data.values);
				setPreviousCursor(res.data.prev);
				setNextCursor(res.data.next);
				setKeysNumber(res.data.$count);
			})
			.catch((err: any) => {
				console.error("Error fetching keys:", err);
			});
	}, [searchQuery]);

	useEffect(() => {
		if (!projectId) return;
		if (searchTranslationQuery === prevSearchTranslationQuery) return;
		setPrevSearchTranslationQuery(searchTranslationQuery);
		searchTranslation(projectId, `name==${searchTranslationQuery}`)
			.then((res: any) => {
				setKeys(res.data.values);
				setPreviousCursor(res.data.prev);
				setNextCursor(res.data.next);
				setKeysNumber(res.data.$count);
			})
			.catch((err: any) => {
				console.error("Error fetching keys:", err);
			});
	}, [searchTranslationQuery]);

	const addNewKey = () => {
		if (!projectId) return;

		createKey(projectId, { key: "", context: "" })
			.then((res: any) => {
				console.log("Key created:", res);
				setKeys([res.data, ...keys]);
				// Redirect to new key page
			})
			.catch((err: any) => {
				console.error("Error creating key:", err);
			});
		// Redirect to new key page
	};

	const refreshOnTranslation = (keyId: string) => {
		if (!projectId) return;
		console.log("Refreshing key:", keyId);
		getKey(projectId, keyId)
			.then((res) => {
				setKeys((prevKeys) => {
					const keysCopy = [...prevKeys];
					const keyIndex = keysCopy.findIndex(
						(key) => key.id === keyId
					);
					if (keyIndex !== -1) {
						keysCopy[keyIndex] = res.data;
						return keysCopy;
					}
					return prevKeys;
				});
			})
			.catch((err: any) => {
				console.error("Error fetching key:", err);
			});
	};

	return (
		<TopBarHeader title={`Project '${project.name}'`}>
			<Box
				sx={{
					display: "block",
					flexDirection: "row",
					alignItems: "right",
					width: "100%",
					backgroundColor: "#ffffff",
					p: "20px",
					borderRadius: "5px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<SearchBar
							label={"Enter key name"}
							setSearchQuery={setSearchQuery}
						/>
						<Box sx={{ mx: 2 }}>
							<SearchBar
								label={"Enter translation"}
								setSearchQuery={setSearchTranslationQuery}
							/>
						</Box>
					</Box>
					<Button
						sx={{ backgroundColor: "#E1AD01", color: "#000000" }}
						variant="contained"
						onClick={() => {
							addNewKey();
						}}
					>
						Add new key
					</Button>
				</Box>
				<Divider sx={{ mt: 2, mb: 2 }} />
				<Typography
					variant="h6"
					component="div"
					sx={{
						fontSize: "20px",
						fontStyle: "italic",
						color: "#858585",
						my: "auto",
						mr: 2,
					}}
				>
					{keysNumber} keys
				</Typography>

				{keys.map((key, index) => (
					<Key
						key={key.id}
						id={key.id}
						name={key.key}
						context={key.context}
						translations={key.translations}
						refreshOnTranslation={refreshOnTranslation}
						refresh={refresh}
					/>
				))}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<IconButton
						onClick={getPreviousPage}
						disabled={!previousCursor}
					>
						Previous
					</IconButton>
					<IconButton onClick={getNextPage} disabled={!nextCursor}>
						Next
					</IconButton>
				</Box>
			</Box>
		</TopBarHeader>
	);
};

export default KeysList;
