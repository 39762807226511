import { AxiosResponse } from 'axios';
import { instance } from './baseApi';
import { config } from '../config/configurations';

export function listProject(): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects`);
}

export function getProjectsKeys(project_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys`);
}

export function getProject(project_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}`);
}

export function createProject(project: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/projects`, project);
}

export function deleteProject(project_id: string): Promise<AxiosResponse> {
    return instance.delete(`/${config.REACT_APP_API_VERSION}/projects/${project_id}`);
}

export function updateProject(project_id: string, project: any): Promise<AxiosResponse> {
    return instance.put(`/${config.REACT_APP_API_VERSION}/projects/${project_id}`, project);
}

export function exportProjectTranslations(project_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/export`, { responseType: 'blob' });
}

export function getLanguages(project_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/languages`);
}

export function addLanguage(project_id: string, language: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/languages`, language);
}

export function deleteLanguage(project_id: string, language_id: string): Promise<AxiosResponse> {
    return instance.delete(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/languages/${language_id}`);
}

export function listKeys(project_id: string, cursor:string, count:boolean): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys?cursor=${cursor}&$count=${count}`);
}

export function createKey(project_id: string, key: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys`, key);
}

export function getKey(project_id: string, key_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}`);
}

export function updateKey(project_id: string, key_id: string, key: any): Promise<AxiosResponse> {
    return instance.put(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}`, key);
}

export function deleteKey(project_id: string, key_id: string): Promise<AxiosResponse> {
    return instance.delete(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}`);
}


export function searchKeys(project_id: string, query: string, cursor:string=""): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/search?query=${query}&cursor=${cursor}`);
}


export function translateKey(project_id: string, key_id: string): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translate`);
}
