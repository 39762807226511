import { styled } from "@mui/material/styles";

export const ContentStyle = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        maxWidth: "284px",
        padding: 48,
        margin: "auto",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        background: "#fff",
    },
    maxWidth: "500px",
    padding: 25,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "#fff",
}));