import axios from 'axios';
import { GlobalVariables, config } from '../config/configurations';

const instance = axios.create({
    withCredentials: true,
    baseURL: config.REACT_APP_URL_BACKEND_API,
    headers: {
        appId: config.REACT_APP_ID,
        version: config.REACT_APP_API_VERSION,
        client: config.REACT_APP_CLIENT,
    }
})

instance.interceptors.response.use(
    (request) => {
        return request;
    },
    async (error) => {
        if (error && error?.response?.status === 401) {
            await instance.post(`${config.REACT_APP_URL_BACKEND_API}/${config.REACT_APP_API_VERSION}/authentication/oauth2/refresh`, {
                grant_type: "refresh_token",
                instance: GlobalVariables.getInstance().getIntegrationMode(),
            })
            .catch((err) => {
                if (err.response.status === 401) {
                    var globalVar = GlobalVariables.getInstance();
                    // Manage chrome add-on router which is a hash router
                    if (globalVar.integrationMode !== "addon") {
                        window.location.href = "/login";
                    } else {
                        window.location.href = "/index.html#/login";
                    }
                }
                return Promise.reject(err);
            });
            return axios(error.config);
        } else {
            return Promise.reject(error);
        }
    }
);

export { instance };
