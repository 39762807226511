import React, { useEffect, useState } from 'react';
import { TopBarHeader } from '../../components/header';
import { createLanguage, deleteLanguage, listLanguages } from '../../apis/languages';
import { Button, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Box, Modal, Select, MenuItem, InputLabel, FormControl, Autocomplete, TextField, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteICon from '@mui/icons-material/Delete';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import { countries } from 'country-data';
import clm from 'country-locale-map';
 
interface Language {
    id: string;
    name: string;
    iso_code: string;
    projects: number;
}

interface CountryInfo {
  iso_code: string;
  name: string;
}

export const ISOLanguagesList: React.FC = () => {
    const [countries_list, setCountriesList] = useState<CountryInfo[]>([]);
    const [languages, setLanguages] = useState<Language[]>([]);
    const [openAddLanguageModal, setOpenAddLanguageModal] = useState(false);
    const [langSelected, setLangSelected] = useState<Language[]>([]);
    const [selectedLanguages, setSelectedLanguages] = useState<any[]>([]);
    const [creationEnded, setCreationEnded] = useState(false);


    useEffect(() => {
        listLanguages().then((res) => {
            setLanguages(res.data);
        }).catch((err) => {
            console.error('Error fetching languages:', err);
        });
        setCountriesList(Array.from(new Set(countries.all.map((country:any) => {
            return {name: country.name, iso_code: country.alpha2}
        }))))
    }, []);

    const handleEditClick = (languageId: string) => {
        setOpenAddLanguageModal(true);
    };

    const handleDelete = (languageId: string) => {
        deleteLanguage(languageId).then((res) => {
            console.log('Language deleted:', res);
            listLanguages().then((res) => {
                setLanguages(res.data);
            }).catch((err) => {
                console.error('Error fetching languages:', err);
            });
        }).catch((err) => {
            console.error('Error deleting language:', err);
        });
    };

    const addNewLanguage = () => {
        setOpenAddLanguageModal(true);
    };

    const changeHandler = (event:any, value: any) => {
        if (value === null) {
            return;
        }
        value = value[value.length - 1];
        if (!value) {
            return;
        }

        setLangSelected(
            [...langSelected, {"name": value.name, "iso_code": value.iso_code, "id": "", "projects": 0 }]
        );
        setSelectedLanguages([...selectedLanguages, value]);
    }

    const handleNewLanguages = () => {
        console.log(langSelected);
        var itemProcessed = 0;
        langSelected.forEach((language) => {
            console.log('Creating language:', language)
            console.log('Getting', clm.getCountryByAlpha2(language.iso_code))
            const locales = clm.getCountryByAlpha2(language.iso_code)?.locales;
            const langs = clm.getCountryByAlpha2(language.iso_code)?.languages;
            locales?.forEach((element, index) => {
                console.log('Creating language:', element, language)
                var obj = {
                    "name": locales?.length ?? 0 > 2 ? `${language.name}, locale ${element}` : language.name,
                    "iso_code": element.replace("_", "-"),
                }
                createLanguage(obj).then((res) => {
                    console.log('Language created:', res);
                    listLanguages().then((res) => {
                        setLanguages([ ...languages, res.data]);
                        itemProcessed++;
                        if (itemProcessed === langSelected.length) {
                            setCreationEnded(true);
                        }
                    }).catch((err) => {
                        console.error('Error fetching languages:', err);
                    });
                }).catch((err) => {
                    console.error('Error creating language:', err);
                });
            });
            
            setOpenAddLanguageModal(false);

        });
    }

    useEffect(() => {
        if (creationEnded){
            setLangSelected([]);
            setSelectedLanguages([]);
            setOpenAddLanguageModal(false);
        }
    }, [creationEnded]);


    const handleChipDelete = (chipToDelete: CountryInfo) => () => {
        setSelectedLanguages((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <TopBarHeader title="Languages" >
           <Box sx={{ display: 'block', flexDirection: 'row', alignItems: 'right', width: "100%" }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'right', width:"100%", mb:"15px" }}  p="10px">
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent:"right", alignItems: 'right' }} marginLeft="0">
                        <Button sx={{backgroundColor:"#E1AD01", color:"#000000"}} variant='contained' onClick={() => { addNewLanguage(); }}>Add new language</Button>
                    </Box>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell width="50%">Name</TableCell>
                                <TableCell width="20%">Iso code</TableCell>
                                <TableCell width="30%">Flag</TableCell>
                                <TableCell width="10%" >#Projects</TableCell>
                                <TableCell width="10%" >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {languages.map((language:Language) => (
                                <TableRow key={language.id} >
                                    <TableCell>{language.name}</TableCell>
                                    <TableCell>{language.iso_code}</TableCell>
                                    <TableCell>
                                        <Box key={language.iso_code} sx={{ display: 'flex', flexDirection: 'row', width:"100%" }}>
                                            {language?.iso_code ? getUnicodeFlagIcon(language?.iso_code?.split('-')[1]) : ""}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{language.projects}</TableCell>
                                    <TableCell sx={{justifyContent:"right", width:"100%", display:"flex"}}>
                                        <IconButton onClick={() => handleEditClick(language.id)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(language.id)}>
                                            <DeleteICon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Modal open={openAddLanguageModal} onClose={() => {setOpenAddLanguageModal(false);}}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <h2>Add new language</h2>
                    <FormControl fullWidth>
                        <Autocomplete 
                        multiple
                            value={selectedLanguages}
                            onChange={changeHandler}
                            options={countries_list}
                            limitTags={3}
                            getOptionLabel={(option) => option?.name}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip 
                                        label={`${getUnicodeFlagIcon(option.iso_code.toLowerCase())} ${option.name} - ${option.iso_code}`}
                                        {...getTagProps({ index })} 
                                        onDelete={() => handleChipDelete(option)} 
                                     />
                                ))
                            }
                            renderOption={(props, option) => (
                                <Box key={option.name} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    {`${getUnicodeFlagIcon(option.iso_code.toLowerCase())} ${option.name} - ${option.iso_code}`}
                                </Box>
                            )}
                            renderInput={(params) => <TextField {...params} label="Choose a country" />}
                        />
                        <Button variant="contained" sx={{backgroundColor:"#E1AD01", color:"#000000", marginTop:"25px"}} type="submit" onClick={() => {handleNewLanguages()}}>Add</Button>
                    </FormControl>
                </Box>
            </Modal>

        </TopBarHeader>
    );
};