import { forceLogout } from "../apis/authentication";

function check_expiry(expiry: string | null | undefined) {
    if (expiry) {
        let now = Math.trunc((new Date().getTime()) / 1000);
        if (parseInt(expiry) > now) {
            return true
        }
    }
    forceLogout();
    return false;

}

export function isCookieSetAndValid(cookieName: string, mode?: string): boolean {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(cookieName + '=')) {
            let value = cookie.split('=')[1];
            return check_expiry(value);
        }
    }

    return false;
}


export function isCookieSet(cookieName: string): boolean {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(cookieName + '=')) {
            return true
        }
    }

    return false;
}

export function getCookie(cookieName: string): string {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        // Check if the cookie starts with the specified name
        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1, cookie.length);
        }
    }

    return "";
}
