import { AxiosResponse } from "axios";
import { GlobalVariables, config } from "../config/configurations";
import { cleanLocalStorage } from "../helpers/localStorage";
import { instance } from "./baseApi";

var globalVar = GlobalVariables.getInstance();


export function forceLogout(): Promise<AxiosResponse> {
  instance
    .post(`/${config.REACT_APP_API_VERSION}/authentication/oauth2/logout`)
    .then((response) => {
      cleanLocalStorage();
    })
    .catch((error) => {
      cleanLocalStorage();
    });
  return Promise.resolve({} as AxiosResponse);
}

export function getAuthUrlClientCredentials(
    provider: string,
    redirectUrl: string
): Promise<AxiosResponse> {
  return instance.post(
    `/${config.REACT_APP_API_VERSION}/authentication/oauth2/authorize`,
    {
        provider: provider,
        redirect_uri: redirectUrl,
        grant_type: "client_credentials",
    }
  );
}

export function getProviderURl(provider: string): Promise<AxiosResponse> {
  return instance.get(
    `/${config.REACT_APP_API_VERSION}/authentication/oauth2/${provider}/authorize`
  );
}

export function getGrantUrl(
  provider: string,
  token: string,
  tokenType: string,
  mode?: string
): Promise<AxiosResponse> {
  return instance.post(
    `/${config.REACT_APP_API_VERSION}/authentication/oauth2/grant?mode=${globalVar.getIntegrationMode()}`,
    {
      grant_type: "sso_login",
      provider: provider,
      token: token,
      token_type: tokenType,
      mode: mode,
    }
  );
}
