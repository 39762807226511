import { Paper, TextField, Typography, Box, Collapse, IconButton } from "@mui/material";
import React, { useState } from "react";
import debounce from 'lodash/debounce';

import { deleteKey, updateKey } from "../../apis/projects";
import { useParams } from "react-router-dom";
import { Translation } from "../translations";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Delete as DeleteIcon } from '@mui/icons-material';


interface KeyProps {
    id: string;
    name: string;
    context: string;
    translations: any[];
    refreshOnTranslation: Function;
    refresh: Function;
}

export const Key: React.FC<KeyProps> = (props: KeyProps) => {
    var { id, name, context, translations } = props;
    const { projectId } = useParams();
    const [key, setKey] = useState<string>(name);
    const [contextText, setContext] = useState<string>(context);
    const [defaultLanguage, setDefaultLanguage] = useState<string>('en-US');
    const [open, setOpen] = useState<boolean>(false);
    
    const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const keyValue = event.target.value.toUpperCase();
        setKey(keyValue);
        if (!projectId) return;
    
        const debouncedUpdateKey = debounce(() => {
            updateKey(projectId, id, { "key": keyValue })
                .then((res) => {
                    console.log('Key updated:', res);
                })
                .catch((err) => {
                    console.error('Error updating key:', err);
                });
        }, 300);
        
        debouncedUpdateKey();
    };

    const handleContextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContext(event.target.value);

        if (!projectId) return;
        updateKey(projectId, id, {"context": event.target.value}).then((res) => {
            console.log('Context updated:', res);
        }).catch((err) => {
            console.error('Error updating context:', err);
        });
    }

    const handleOpen = () => {
        setOpen(!open);
    }

    const handleEditOpen = () => {
        setOpen(true);
    }

    const deleteSelectedKey = () => {
        console.log('Deleting key:', id);
        if (!projectId) return;
        deleteKey(projectId, id).then((res:any) => {
            props.refresh();
        }).catch((err:any) => {
            console.error('Error deleting key:', err);
        });
    }

    return(
        <Paper sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            margin: '10px',
            p: 2
        }}>
            <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center", mb: 1 }}>
                <IconButton aria-label="delete" sx={{float: "right"}} onClick={deleteSelectedKey}>
                    <DeleteIcon />
                </IconButton>
            </Box>
            <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center", mt: "15px", mb: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', verticalAlign:"center", justifyContent:"space-between", mt: 2, mb: 1 }}>
                    <TextField 
                        name="name"
                        label="Key name"
                        value={key}
                        sx={{width: "600px"}}
                        onChange={handleKeyChange}
                        onClick={handleEditOpen}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                    {open ? <ExpandLess onClick={handleOpen} sx={{my: "auto"}} /> : <ExpandMore  onClick={handleOpen} sx={{my: "auto"}}  />} 
                </Box>
                <Typography component="div" sx={{ fontSize: "10px", fontStyle:"italic", color:"#858585", mt:1, ml: 2}}>
                    Key name used in the code to identify the translation
                </Typography>
                
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center", mt: 2, mb: 1 }}>
                    <TextField multiline label="Key context" value={contextText} sx={{width: "600px"}} onChange={handleContextChange} />
                    <Typography component="div" sx={{ fontSize: "10px", fontStyle:"italic", color:"#858585", my:"auto", ml: 2}}>
                        Give some context for the translator to help understand the key (optional and multiline)
                    </Typography>
                </Box>
                <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center",  borderLeft: "solid 2px #ECECEC", ml: 5, mb: 1 }}>
                    {translations.map((translation, translationIndex) => (
                        <Translation 
                            key={translationIndex}
                            id={translation.id}
                            keyId={id}
                            source_language={translation.source_language}
                            target_language={translation.target_language}
                            source_text={translation.source_text}
                            target_text={translation.target_text}
                            defaultLanguage={defaultLanguage}
                            refreshOnTranslation={props.refreshOnTranslation}
                        />
                    ))}
                </Box>
            </Collapse>
        </Paper>
    );
}