import { AxiosResponse } from 'axios';
import { instance } from './baseApi';
import { config } from '../config/configurations';

export function getTranslations(project_id: string, key_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translations`);
}

export function createTranslation(project_id: string, key_id: string, translation: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translations`, translation);
}

export function deleteTranslation(project_id: string, key_id: string, translation_id: string): Promise<AxiosResponse> {
    return instance.delete(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translations/${translation_id}`);
}

export function getTranslation(project_id: string, key_id: string, translation_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translations/${translation_id}`);
}

export function updateTranslation(project_id: string, key_id: string, translation_id: string, translation: any): Promise<AxiosResponse> {
    return instance.put(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/keys/${key_id}/translations/${translation_id}`, translation);
}

export function searchTranslation(project_id:string, query:string, cursor:string=""): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/projects/${project_id}/translations/search?query=${query}&cursor=${cursor}`
    )
}
