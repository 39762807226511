import { Box,  Container, Typography } from "@mui/material";
import React, { useEffect } from "react";

import { motion } from "framer-motion";
import { LoginProps } from "../../interfaces/ILogin";

import Logo from "../../components/login/logo";
import SocialAuth from "../../components/login/social";
import "./login.css";

import { cleanMeetingFromLocalStorage } from "../../helpers/localStorage";
import { ContentStyle } from "../../style/contentStyle";
import { HeadingStyle } from "../../style/headingStyle";
import { RootStyle } from "../../style/rootStyle";

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
    initial: {
        y: 60,
        opacity: 0,
        transition: { duration: 0.6, ease: easing },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.6,
            ease: easing,
        },
    },
};

const Login: React.FC<LoginProps> = (props: LoginProps) => {

    useEffect(() => {
        cleanMeetingFromLocalStorage();
    }, []);


    return (
        <RootStyle className="root-container">
            <Container>
                <ContentStyle>
                    <HeadingStyle component={motion.div} {...fadeInUp}>
                        <Logo />
                        <Typography sx={{ color: "text.secondary", mb: 5 }}>
                            Login to your account
                        </Typography>
                    </HeadingStyle>
                    <Box component={motion.div} {...fadeInUp}>
                        <SocialAuth direction="row" {...props} />
                    </Box>
                </ContentStyle>
            </Container>
        </RootStyle >
    );
};

export default Login;