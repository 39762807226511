import { Autocomplete, Box, Button, Checkbox, Chip, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { TopBarHeader } from '../../components/header';
import SendIcon from '@mui/icons-material/Send';
import { createProject } from '../../apis/projects';
import { useNavigate } from 'react-router-dom';
import { listLanguages } from '../../apis/languages';
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

const CreateProjectPage: React.FC = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [languages, setLanguages] = useState<any[]>([]);
    const [selectedLanguages, setSelectedLanguages] = useState<any[]>([]);
    const [defaultLanguage, setDefaultLanguage] = useState<any>();
    const [toastData, setToastData] = useState({ open: false, message: '' });

    useEffect(() => {
        listLanguages().then((res) => {
            setLanguages(res.data);
        }).catch((err) => {
            console.error('Error fetching languages:', err);
        });
    }, []);

    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
          }      
        setToastData({ open: false, message: '' });
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        // TODO: Handle form submission, e.g. send data to server
        console.log('Name:', name);
        console.log('Description:', description);
        if(selectedLanguages.length === 0) {
            setToastData({ open: true, message: 'Please select at least one language' });
            return;
        }
        else if(!defaultLanguage) {
            setToastData({ open: true, message: 'Please select a default language' });
            return;
        }
        else {
            createProject({ name, description, default: defaultLanguage.id, languages:selectedLanguages }).then((res) => {
                console.log('Project created:', res);
                navigate('/');
            }).catch((err) => {
                console.error('Error creating project:', err);
    
            });
        }
    };

    const updateSelectedLanguage = (language:any) => {
        console.log('Selected language:', language);
        if (selectedLanguages.includes(language)) {
            setSelectedLanguages(selectedLanguages.filter((l) => l !== language));
        }
        else {
            setSelectedLanguages([...selectedLanguages, language]);
        }
    }

    useEffect(() => {
        if (defaultLanguage && selectedLanguages.length === 0) {
            setDefaultLanguage(null);
        }
        if (selectedLanguages.indexOf(defaultLanguage) === -1) {
            setDefaultLanguage(null);
        }
    }, [selectedLanguages]);

    return (
        <TopBarHeader title="Create New Project">
            <Box sx={{ p: 3, margin:"auto", width:"fit-content", backgroundColor: "#ffffff", borderRadius:"5px" }}>
                <Box sx={{ textAlign: "center" }}>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', verticalAlign:"center", mt: "15px", mb: 1 }}>
                            <Box sx={{ display: 'block', flexDirection: 'row', verticalAlign:"center", mt: "15px", mb: 1 }}>
                                <Box>
                                    <TextField label="Name" id="name" value={name} onChange={handleNameChange} sx={{width:"350px"}} required/>
                                </Box>
                                <Box sx={{
                                    marginTop: 3
                                }}>
                                    <TextField  label="Description" id="description" value={description} onChange={handleDescriptionChange} multiline={true} sx={{width:"350px"}}/>
                                </Box>
                                <Box sx={{
                                    marginTop: 3
                                }}>
                                    {selectedLanguages &&
                                        <Autocomplete 
                                            id="tags-standard"
                                            options={selectedLanguages}
                                            value={defaultLanguage}
                                            getOptionLabel={(option) => `${getUnicodeFlagIcon(option?.iso_code?.split('-')[1])} ${option?.name}`}
                                            onChange={(event, newValue) => {setDefaultLanguage(newValue)}}
                                            noOptionsText={'Please select at least one language'}
                                            renderTags={(value, getTagProps) => {
                                                return value.map((option, index) => (
                                                    <Chip label={`${getUnicodeFlagIcon(option.iso_code.split('-')[1])} ${option.name}`} {...getTagProps({ index })} />
                                                ))
                                            }}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Typography
                                                        component='div'
                                                        sx={{ fontWeight: selected ? 500 : 400 }}
                                                    >
                                                        {getUnicodeFlagIcon(option.iso_code.split('-')[1])} {option.name}
                                                    </Typography>
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label="Select default languages"
                                                />
                                            )}
                                        />
                                    }
                                </Box>
                            </Box>
                            <FormGroup sx={{ display: 'block', width: 'fit-content', ml:3}}>
                                <Typography variant="h6" sx={{ textAlign:"start" }}>Languages</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', minWidth:"510px", width: "fit-content", mt:0,  }}>
                                    {languages.map((language) => (
                                        <FormControlLabel 
                                            key={language.iso_code}
                                            control={<Checkbox onClick={() => {updateSelectedLanguage(language)}} />}
                                            label={`${getUnicodeFlagIcon(language?.iso_code?.split('-')[1])} ${language.name} - ${language.iso_code}`}
                                            sx={{ 
                                                mt: 0,
                                                display: 'flex',
                                                flex: "1 1 49%",
                                                width: "fit-content",
                                            }}
                                        />
                                    ))}
                                </Box>
                            </FormGroup>
                        </Box>
                        <Button
                            type="submit"
                            variant='contained'
                            sx={{
                                backgroundColor:"#E1AD01",
                                color:"#000000",
                                marginTop: 3
                            }}
                            startIcon={<SendIcon />}
                        >
                            Create
                        </Button>
                    </form>
                </Box>
            </Box>
            <Snackbar
                onClose={handleToastClose}
                open={toastData.open}
                autoHideDuration={3000}
            >
                <Alert
                    onClose={handleToastClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastData.message}
                </Alert>
            </Snackbar>
        </TopBarHeader>
    );
};

export default CreateProjectPage;