import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from 'react';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { forceLogout } from './apis/authentication';

import { ProtectedRoute } from "./components/authentication/protected";
import { customTheme } from "./components/palette";
import { config } from './config/configurations';
import { isCookieSet, isCookieSetAndValid } from './helpers/cookies';

import Login from "./pages/login/login";


import './App.css';
import ProjectsList from "./pages/projects";
import CreateProjectPage from "./pages/projects/newProject";
import { ISOLanguagesList } from "./pages/projects/languages";
import KeysList from "./pages/projects/keys";

function App() {
  useEffect(() => {
    if (!isCookieSetAndValid(config.COOKIE_SESSION_EXPIRY_NAME)) {
      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
      }
    }
    if (isCookieSet(config.COOKIE_SESSION_EXPIRY_NAME) && window.location.pathname === "/login") {
      forceLogout();
    }
  }, []);
  return (
   <ThemeProvider theme={customTheme} >
      <BrowserRouter>
        <CssBaseline />
        <Routes>
          <Route path="/" element={
            <ProtectedRoute >
              <ProjectsList/>
            </ProtectedRoute>
          } />
          <Route path="/projects/new" element={
            <ProtectedRoute >
              <CreateProjectPage />
            </ProtectedRoute>
          } />
          <Route path="/projects/:projectId/keys" element={
            <ProtectedRoute >
              <KeysList />
            </ProtectedRoute>
          } />
          <Route path="/languages" element={
            <ProtectedRoute >
              <ISOLanguagesList />
            </ProtectedRoute>
          } />
          <Route path="/login" element={
            <Login mode="web" callback={() => ({})} />
          } />
          <Route path='/meetings/:meetingId' element={
            <ProtectedRoute >
              <></>
            </ProtectedRoute>
          } />

        </Routes>
      </BrowserRouter>
    </ThemeProvider >
  );
}

export default App;
