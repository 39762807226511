import { AxiosResponse } from 'axios';
import { instance } from './baseApi';
import { config } from '../config/configurations';

export function listLanguages(): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/languages`);
}

export function getLanguage(language_id: string): Promise<AxiosResponse> {
    return instance.get(`/${config.REACT_APP_API_VERSION}/languages/${language_id}`);
}

export function createLanguage(language: any): Promise<AxiosResponse> {
    return instance.post(`/${config.REACT_APP_API_VERSION}/languages`, language);
}

export function deleteLanguage(language_id: string): Promise<AxiosResponse> {
    return instance.delete(`/${config.REACT_APP_API_VERSION}/languages/${language_id}`);
}
