import {
    ArrowBackIos as ArrowBackIosIcon,
    Close as CloseIcon,
    ExitToApp as ExitToAppIcon,
    Groups as GroupsIcon,
    Menu as MenuIcon,
    Mic as MicIcon,
    Search as SearchIcon,
    Send as SendIcon,
    SettingsAccessibility as SettingsAccessibilityIcon,
    Settings as SettingsIcon,
    Mail as MailIcon,
    Inbox as InboxIcon,
    Add as AddIcon,
    Create as CreateIcon,
} from '@mui/icons-material';
import { AppBar, Box, Button, CircularProgress, Container, CssBaseline, Divider, Drawer, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Snackbar, Toolbar, Tooltip, Typography, alpha, styled } from "@mui/material";
import React, { useState } from "react";
import { forceLogout } from "../../apis/authentication";
import { GlobalVariables } from "../../config/configurations";
import { cleanMeetingFromLocalStorage } from "../../helpers/localStorage";

import "../../components/media/header/meetingMenu.css";
import "../../components/media/header/menuBar.css";
import { Navigate, useNavigate } from 'react-router-dom';

const drawerWidth = 240;


const StyledSearch = styled('div')(
    ({ theme }) => ({
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.primary.main, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    })
);

const SearchIconWrapper = styled('div')(
    ({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }));

const StyledInputBase = styled(InputBase)(
    ({ theme }) => ({
        color: 'inherit',
        '& .MuiInputBase-input': {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    }));

//search as JSX
const search = (
    <StyledSearch>
        <SearchIconWrapper>
            <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} />
    </StyledSearch>
)

var globalVar = GlobalVariables.getInstance();

interface TopBarHeaderProps {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    mode?: "addon" | "standalone" | "rfp" | "";
}

const menu = [
    {
      "name": "Languages",
      "icon": <SettingsAccessibilityIcon />,
      "url": "/languages"
    },
    {
      "name": "Projects",
      "icon": <CreateIcon />,
      "url": "/"
    },

]

export const TopBarHeader: React.FC<TopBarHeaderProps> = (props: TopBarHeaderProps) => {
    const navigate = useNavigate();
    const [open, setState] = useState(false);
    const [displaySnackbar, setDisplaySnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarType, setSnackbarType] = useState<"success" | "error" | "warning" | "info" | undefined>("success");


    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        //changes the function state according to the value of open
        setState(open);
    };

  return (

    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title ? props.title : "Title"}
          </Typography>
          <Button color="inherit" onClick={() => {
            forceLogout();
             navigate("/login")}}
            >
              Logout
            </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menu.map((obj, index) => (
              <ListItem key={obj.name} disablePadding onClick={() => navigate(obj.url)}>
                <ListItemButton>
                  <ListItemIcon>
                    { obj.icon}
                  </ListItemIcon>
                  <ListItemText primary={obj.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        { props.children }
      </Box>
    </Box>

    );
}
