/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Navigate } from 'react-router-dom';

import { isCookieSetAndValid } from '../../helpers/cookies';
import { config } from '../../config/configurations';


export const ProtectedRoute = (
    {
        children
    }: {
        children: React.ReactNode,
    }
) => {

    return (
        <>
            {isCookieSetAndValid(config.COOKIE_SESSION_EXPIRY_NAME) &&
                <React.Fragment>
                    {children}
                </React.Fragment>}
            {!isCookieSetAndValid(config.COOKIE_SESSION_EXPIRY_NAME) && <Navigate to="/login" />}
        </>
    );
}