export function cleanLocalStorage() {
    localStorage.clear();
}

export function cleanMeetingFromLocalStorage() {
    localStorage.removeItem("meetingId");
    localStorage.removeItem("transcriptId");
    localStorage.removeItem("noteId");
    localStorage.removeItem("novel__content");
    localStorage.removeItem("meetingInfo");
}