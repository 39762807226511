import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Select,
	MenuItem,
	IconButton,
	Box,
	Toolbar,
	Stack,
	Modal,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
	CircularProgress,
	Tooltip,
} from "@mui/material";
import {
	ContentCopy as ContentCopyIcon,
	Flag as FlagIcon,
	Edit as EditIcon,
	FileDownload as FileDownloadIcon,
	Send as SendIcon,
} from "@mui/icons-material";
import {
	addLanguage,
	deleteLanguage,
	exportProjectTranslations,
	getLanguages,
	listProject,
} from "../../apis/projects";
import * as iso6391 from "iso-639-1";
import { TopBarHeader } from "../../components/header";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { languages } from "country-data";
import { listLanguages } from "../../apis/languages";

interface Language {
	id: string;
	name: string;
	iso_code: string;
	projects: number;
}

interface Project {
	id: string;
	name: string;
	keys: number;
	languages: Language[];
}

const ProjectsList: React.FC = () => {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [projects, setProjects] = useState<Project[]>([]);
	const [cursorNext, setCursorNext] = useState<string>("");
	const [cursorPrevious, setCursorPrevious] = useState<string>("");
	const [selectedProject, setSelectedProject] = useState<string>();
	const [selectedLanguages, setSelectedLanguages] = useState<Language[]>();
	const [updatedLanguages, setUpdatedLanguages] = useState<Language[]>();
	const [organizationLanguages, setOrganizationLanguages] =
		useState<Language[]>();

	useEffect(() => {
		// Fetch projects from API and update state
		updateProjecList();
	}, []);

	const updateProjecList = () => {
		listProject()
			.then((res) => {
				setProjects(res.data.values);
				setCursorNext(res.data.next);
				setCursorPrevious(res.data.prev);
			})
			.catch((err) => {
				console.error("Error fetching projects:", err);
			});
	};

	const handleProjectClick = (projectId: string) => {
		// Redirect to translations page associated with the project
		navigate(`/projects/${projectId}/keys`);
	};

	const exportTraductions = (projectId: string) => {
		// Redirect to translations page associated with the project
		exportProjectTranslations(projectId)
			.then((res) => {
				console.log("Exporting translations:", res);
				const blob = new Blob([res.data], { type: "application/zip" });
				const url = window.URL.createObjectURL(blob);

				const link = document.createElement("a");
				link.href = url;

				const contentDisposition = res.headers["content-disposition"];
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(contentDisposition);
				let filename = "files.zip"; // Default filename if not found
				if (matches != null && matches[1]) {
					filename = matches[1].replace(/['"]/g, "");
				}
				link.setAttribute("download", filename);

				document.body.appendChild(link);
				link.click();

				// Cleanup
				URL.revokeObjectURL(url);
				document.body.removeChild(link);
			})
			.catch((err) => {
				console.error("Error exporting translations:", err);
			});
	};

	const handleLanguageSelect = async (projectId: string) => {
		// Call API to add new language to the project
		setSelectedProject(projectId);
		getLanguages(projectId)
			.then((res: any) => {
				setSelectedLanguages(res.data);
				setUpdatedLanguages(res.data);
				listLanguages()
					.then((res) => {
						setOrganizationLanguages(res.data);
						setOpenModal(true);
					})
					.catch((err) => {
						console.error("Error fetching languages:", err);
					});
			})
			.catch((err) => {
				console.error("Error fetching languages:", err);
			});
	};
	const updateSelectedLanguage = (language: any) => {
		console.log("Selected language:", language);
		if (updatedLanguages?.includes(language)) {
			setUpdatedLanguages(
				updatedLanguages?.filter((l) => l !== language)
			);
		} else {
			setUpdatedLanguages([...(updatedLanguages ?? []), language]);
		}
	};

	const removeUpdateSelectedLanguage = (language: any) => {
		console.log("Selected language:", language);
		if (updatedLanguages?.includes(language)) {
			setUpdatedLanguages(
				updatedLanguages?.filter((l) => l !== language)
			);
		}
	};

	const updateProjectLanguage = () => {
		console.log("Selected languages:", updatedLanguages);
		if (!selectedProject) return;

		selectedLanguages?.map((lang) => {
			if (!updatedLanguages?.includes(lang)) {
				console.log("Deleting language:", lang.id);
				deleteLanguage(selectedProject, lang.id)
					.then((res) => {
						console.log("Language deleted:", res);
						console.log("Selected languages:", selectedLanguages);
						console.log(
							"filtered languages:",
							selectedLanguages?.filter((l) => l !== lang)
						);
						setSelectedLanguages(
							selectedLanguages?.filter((l) => l !== lang)
						);
					})
					.catch((err) => {
						console.error("Error deleting language:", err);
					});
			}
			console.log("Not deleting language:", lang.id);
			updatedLanguages?.filter((l) => l === lang);
			console.log("Updated languages:", updatedLanguages);
			return null;
		});
		console.log("Adding languages:", updatedLanguages);
		if (updatedLanguages?.length === 0) {
			updateProjecList();
			setOpenModal(false);
			return;
		}

		var counter = 0;
		updatedLanguages?.forEach((lang) => {
			if (!selectedLanguages?.includes(lang)) {
				console.log("Adding language:", lang.id);
				addLanguage(selectedProject, lang)
					.then((res) => {
						console.log("Language added:", res);
						counter++;
					})
					.catch((err) => {
						counter++;
						console.error("Error adding language:", err);
					})
					.finally(() => {
						console.log("Counter:", counter);
						if (counter === updatedLanguages.length) {
							updateProjecList();
							setOpenModal(false);
						}
					});
			} else {
				counter++;
				if (counter === updatedLanguages.length) {
					updateProjecList();
					setOpenModal(false);
				}
			}
		});
	};
	const handleProjectCopyId = (projectId: string) => {
		// Copy the ID in the keyboard
		navigator.clipboard.writeText(projectId);
	};

	return (
		<>
			<TopBarHeader title="Project list">
				<Box
					sx={{
						display: "block",
						flexDirection: "row",
						alignItems: "right",
						width: "100%",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "right",
							width: "100%",
							mb: "15px",
						}}
						p="10px"
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "right",
								alignItems: "right",
							}}
							marginLeft="0"
						>
							<Button
								sx={{
									backgroundColor: "#E1AD01",
									color: "#000000",
								}}
								variant="contained"
								onClick={() => navigate("/projects/new")}
							>
								Create New Project
							</Button>
						</Box>
					</Box>

					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell width="5%"></TableCell>
									<TableCell width="30%">Name</TableCell>
									<TableCell width="10%">#Keys</TableCell>
									<TableCell width="10%">
										#Languages
									</TableCell>
									<TableCell width="35%">Languages</TableCell>
									<TableCell width="10%">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{projects.map((project) => (
									<TableRow key={project.id}>
										<TableCell>
											<Tooltip
												title="Copy Project ID"
												arrow
											>
												<IconButton
													onClick={() =>
														handleProjectCopyId(
															project.id
														)
													}
												>
													<ContentCopyIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
										<TableCell>{project.name}</TableCell>
										<TableCell>{project.keys}</TableCell>
										<TableCell>
											{project.languages?.length}
										</TableCell>
										<TableCell
											sx={{
												columnGap: 3,
												columnCount: 4,
											}}
										>
											{project.languages.map(
												(language) => (
													<Box
														key={language.iso_code}
														sx={{
															display: "flex",
															flexDirection:
																"row",
															width: "100%",
														}}
													>
														{getUnicodeFlagIcon(
															language?.iso_code?.split(
																"-"
															)[1]
														)}{" "}
														-{" "}
														{
															language?.iso_code?.split(
																"-"
															)[0]
														}
													</Box>
												)
											)}
										</TableCell>
										<TableCell>
											<Tooltip
												title="Edit project keys"
												arrow
											>
												<IconButton
													onClick={() =>
														handleProjectClick(
															project.id
														)
													}
												>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip
												title="Add or remove languages"
												arrow
											>
												<IconButton
													onClick={() =>
														handleLanguageSelect(
															project.id
														)
													}
												>
													{!openModal && <FlagIcon />}
													{openModal && (
														<CircularProgress />
													)}
												</IconButton>
											</Tooltip>
											<Tooltip
												title="Export translations"
												arrow
											>
												<IconButton
													onClick={() =>
														exportTraductions(
															project.id
														)
													}
												>
													<FileDownloadIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
				<Modal
					open={openModal}
					onClose={() => {
						setOpenModal(false);
						setUpdatedLanguages([]);
					}}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "fit-content",
							bgcolor: "background.paper",
							border: "2px solid #000",
							boxShadow: 24,
							p: 4,
							overflow: "auto",
							maxHeight: "90%",
						}}
					>
						<FormGroup
							sx={{
								display: "block",
								width: "fit-content",
								ml: 3,
							}}
						>
							<Typography
								variant="h6"
								sx={{ textAlign: "start", mb: "15px" }}
							>
								Select the languages you want to add to the
								project
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									flexWrap: "wrap",
									justifyContent: "space-between",
									minWidth: "510px",
									width: "fit-content",
									mt: 0,
								}}
							>
								{[
									selectedLanguages,
									organizationLanguages?.filter(
										(lang) =>
											!selectedLanguages?.some(
												(selectLang) =>
													lang.id === selectLang.id
											)
									),
								]?.map((list, index) =>
									list?.map((language: any) => (
										<FormControlLabel
											key={language.iso_code}
											control={
												<Checkbox
													checked={updatedLanguages?.includes(
														language
													)}
													onClick={(
														event: React.MouseEvent<HTMLElement>
													) => {
														if (
															event.target instanceof
																HTMLInputElement &&
															event.target.checked
														) {
															updateSelectedLanguage(
																language
															);
														} else {
															removeUpdateSelectedLanguage(
																language
															);
														}
													}}
												/>
											}
											label={`${getUnicodeFlagIcon(
												language?.iso_code?.split(
													"-"
												)[1]
											)} ${language.name} - ${
												language.iso_code
											}`}
											sx={{
												mt: 0,
												display: "flex",
												flex: "1 1 49%",
												width: "fit-content",
											}}
										/>
									))
								)}
							</Box>
						</FormGroup>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								width: "100%",
								m: "auto",
								mt: "25px",
							}}
						>
							<Button
								sx={{
									backgroundColor: "#E1AD01",
									m: "auto",
									color: "#000000",
									marginTop: "25px",
								}}
								type="submit"
								startIcon={<SendIcon />}
								onClick={() => {
									updateProjectLanguage();
								}}
							>
								Add new language
							</Button>
						</Box>
					</Box>
				</Modal>
			</TopBarHeader>
		</>
	);
};

export default ProjectsList;
