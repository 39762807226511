import {
  Google as GoogleIcon,
  Microsoft as MicrosoftIcon,
} from "@mui/icons-material";
import { Button, IconButton, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { getProviderURl } from "../../apis/authentication";
import { cleanMeetingFromLocalStorage } from "../../helpers/localStorage";
import { SocialLoginProps } from "../../interfaces/ILogin";


const SocialAuth: React.FC<SocialLoginProps> = (props: SocialLoginProps) => {
  const getProviderURlInAnyCase = async (provider: string) => {
      getProviderURl(provider).then((res) => {
        cleanMeetingFromLocalStorage();
        window.location.href = res.data.redirect;
      });
  };


  useEffect(() => {
  }, []);

  return (
    <>
      <Stack direction={props.direction} spacing={2}>
        {props.mode !== "office-add-in" &&
            <>
            { props.direction === "row" && 
              <IconButton
                onClick={() => {
                  getProviderURlInAnyCase("google");
                }}
                sx={{
                  border: "2px solid #ccc",
                  borderRadius: "5px",
                  padding: "0.5675rem",
                  flex: 1,
                }}
              >
                <GoogleIcon />
              </IconButton>
            }
            { props.direction === "column" &&
            <Button 
              fullWidth
              size="large"
              variant="outlined"
              onClick={() => {
                  getProviderURlInAnyCase("google");
                }}
              sx={{
                border: "2px solid #ccc",
                borderRadius: "5px",
                padding: "0.5675rem",
                flex: 1,
                color: "text.secondary",
              }}
              startIcon={<GoogleIcon />}
            >
              Google
            </Button>
            }
            </>
        }
        <>
        { props.direction === "row" &&
          <IconButton
            onClick={() => {
              getProviderURlInAnyCase("microsoft");
            }}
            sx={{
              border: "2px solid #ccc",
              borderRadius: "5px",
              padding: "0.5675rem",
              flex: 1,
            }}
          >
            <MicrosoftIcon />
          </IconButton>
        }
        { props.direction === "column" &&
          <Button 
            fullWidth
            size="large"
            variant="outlined"
            onClick={() => {
              getProviderURlInAnyCase("microsoft");
            }}
            sx={{
              border: "2px solid #ccc",
              borderRadius: "5px",
              padding: "0.5675rem",
              flex: 1,
              color: "text.secondary",
            }}
            startIcon={<MicrosoftIcon />}
          >
            Microsoft
          </Button>
        }
        </>
      </Stack>
    </>
  );
};

export default SocialAuth;
