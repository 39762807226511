const environment = process.env.environment || "production";

export class GlobalVariables {
  private static instance: GlobalVariables | null = null;
  public integrationMode: string = "web";
  public variables: any = {};

  private constructor() { }

  static getInstance(): GlobalVariables {
    if (GlobalVariables.instance === null) {
      GlobalVariables.instance = new GlobalVariables();
    }
    return GlobalVariables.instance;
  }
  public setIntegrationMode(mode: string) {
    var app_configuration = this.getApplicationConfiguration();
    if (app_configuration) {
      app_configuration.integrationMode = mode;
      localStorage.setItem(
        "app_configuration",
        JSON.stringify(app_configuration)
      );
    } else {
      localStorage.setItem(
        "app_configuration",
        JSON.stringify({ integrationMode: mode })
      );
    }
  }

  public set(key: string, value: any) {
    this.variables[key] = value;
  }

  public get(key: string) {
    try {
      return this.variables[key];
    } catch (e) {
      return null;
    }
  }

  public del(key: string) {
    try {
      delete this.variables[key];
    } catch (e) { }
  }

  private getApplicationConfiguration() {
    var app_configuration = localStorage.getItem("app_configuration");
    if (app_configuration) {
      var app_configuration_json = JSON.parse(app_configuration);
      return app_configuration_json;
    } else {
      return null;
    }
  }

  public getIntegrationMode() {
    var app_configuration = this.getApplicationConfiguration();

    if (app_configuration) {
      return app_configuration.integrationMode;
    } else {
      return null;
    }
  }
}

const development = {
  REACT_APP_ID: "sbrain-f6c21fc8-2989-4ecc-8db2-aeb665880e89",
  REACT_APP_CLIENT: "sbrain-web",
  REACT_APP_URL_BACKEND_API: "http://localhost:8765",
  REACT_APP_API_VERSION: "v1",
  REACT_APP_BASE_URL: "http://localhost:3000",
  COOKIE_SESSION_EXPIRY_NAME: "session-expiry",
};

const production = {
  REACT_APP_ID: "sbrain-f6c21fc8-2989-4ecc-8db2-aeb665880e89",
  REACT_APP_CLIENT: "sbrain-web",
  REACT_APP_URL_BACKEND_API: "https://api.i18n.sbrain.io",
  REACT_APP_API_VERSION: "v1",
  REACT_APP_BASE_URL: "https://i18n.sbrain.io",
  COOKIE_SESSION_EXPIRY_NAME: "session-expiry",
};

export var config = environment === "development" ? development : production;

/*
export default {
    environment,
    port: process.env.PORT || 8765,
    database: {
        host: process.env.DATABASE_HOST || 'localhost',
        port: process.env.DATABASE_PORT || 27017,
        name: process.env.DATABASE_NAME || 'mydb',
        user: process.env.DATABASE_USER || 'root',
        password: process.env.DATABASE_PASSWORD || 'root',
    },
    jwt: {
        secret: process.env.JWT_SECRET || 'secret',
        expiresIn: process.env.JWT_EXPIRES_IN || '1h',
    },
    redis: {
        host: process.env.REDIS_HOST || 'localhost',
        port: process.env.REDIS_PORT || 6379,
        password: process.env.REDIS_PASSWORD || 'redis',
    },
    rabbitmq: {
        host: process.env.RABBITMQ_HOST || 'localhost',
        port: process.env.RABBITMQ_PORT || 5672,
        user: process.env.RABBITMQ_USER || 'guest',
        password: process.env.RABBITMQ_PASSWORD || 'guest',
    },
    elasticSearch: {
        host: process.env.ELASTICSEARCH_HOST || 'localhost',
        port: process.env.ELASTICSEARCH_PORT || 9200,
    },
    kibana: {
        host: process.env.KIBANA_HOST || 'localhost',
        port: process.env.KIBANA_PORT || 5601,
    },
    logstash: {
        host: process.env.LOGSTASH_HOST || 'localhost',
        port: process.env.LOGSTASH_PORT || 5000,
    },
    log: {
        level: process.env.LOG_LEVEL || 'debug',
    },
    cors: {
        origin: process.env.CORS_ORIGIN || '*',
    },
    mail: {
        host: process.env.MAIL_HOST || 'smtp.gmail.com',
        port: process.env.MAIL_PORT || 587,
        user: process.env.MAIL_USER || '',
        password: process.env.MAIL_PASSWORD || '',
    },
    admin: {
        username: process.env.ADMIN_USERNAME || 'admin',
        password: process.env.ADMIN_PASSWORD || 'admin',
    },
    client: {
        host: process.env.CLIENT_HOST || 'http://localhost:3000',
    },
    server: {
        host: process.env.SERVER_HOST || 'http://localhost:8765',
    },
};
*/
